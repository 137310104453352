import React from "react";
import styles from "./xcyj.module.css";

const App: React.FC = () => {
    return (
        <div className="page">
            <div className="styles.content">
            </div>
            <div className="footer">
                <p>活动规则：本活动解释权归主办方所有。</p>
            </div>
        </div>
    );
};

export default App;