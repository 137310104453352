import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./page/Home";
import PrivacyPolicy from "./h5/PrivacyPolicy";
import UserProtocol from "./h5/UserProtocol";
import CommunityNorms from "./h5/CommunityNorms";
import Share from "./h5/Share";
import Sample from "./h5/Sample";
import Xcyj from "./h5/activity/20241227/xcyj";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/userProtocol" element={<UserProtocol />} />
        <Route path="/communityNorms" element={<CommunityNorms />} />
        <Route path="/share" element={<Share />} />
        <Route path="/app/sample" element={<Sample />} />
        <Route path="/h5/activity/xcyj" element={<Xcyj />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
