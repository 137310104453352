import React, { useState } from 'react';
import styles from '../assets/styles/home.module.css';
import logo from '../assets/images/home/logo.svg';

const Home: React.FC = () => {
  const [currentPage, setCurrentPage] = useState('home');

  const switchPage = (page: 'home' | 'about') => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.homepage}>
      {/* Header Section */}
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={logo} alt="问旅" />
        </div>
        <nav className={styles.navbar}>
          <div className={styles.menu}>
            <span onClick={() => switchPage('home')}>首页</span>
            <span onClick={() => switchPage('about')}>关于我们</span>
          </div>
        </nav>
      </header>

      {/* Page Content */}
      {currentPage === 'home' && (
        <>
          {/* Main Banner Section */}
          <section className={styles.banner}>
            <div className={styles.background}>
              <div className={`${styles.cloud} ${styles.left}`}></div>
              <div className={`${styles.cloud} ${styles.right}`}></div>
              <div className={`${styles.suitcase} ${styles.left}`}></div>
              <div className={`${styles.suitcase} ${styles.right}`}></div>
              <h1 className={styles.title}>你要的旅行很简单</h1>
            </div>
          </section>

          {/* Content Section */}
          <section className={styles.content}>
            <div className={`${styles.app} ${styles.pic1}`}></div>
            <div className={`${styles.app} ${styles.pic2}`}></div>
            <div className={`${styles.app} ${styles.pic3}`}></div>
            <div className={styles.content_textarea}>
              <h2>丰富的旅行社区</h2>
              <p>你想要的旅行信息这儿都有</p>
            </div>
          </section>

          {/* Route Planning Section */}
          <section className={styles.routePlanning}>
            <div className={`${styles.app} ${styles.pic4}`}></div>
            <div className={`${styles.app} ${styles.pic5}`}></div>
            <div className={styles.routePlanning_textarea}>
              <h2>轻松的路线规划</h2>
              <p>一键生成你想要的路线</p>
            </div>
          </section>
        </>
      )}

      {currentPage === 'about' && (
        <section className={styles.about}>
          <div>
            <div className={`${styles.cloud} ${styles.left}`}></div>
            <div className={`${styles.cloud} ${styles.right}`}></div>
            <div className={`${styles.person} ${styles.personLeft}`}></div>
            <div className={`${styles.person} ${styles.personRight}`}></div>
            <p>
              我们是一个致力于打造完美自由行体验的团队，我们希望通过设计解决自由行中的各种问题，让每个人都有最适合自己的旅行体验。
            </p>
            <p>联系我们：<strong>business@haolvheshan.cn</strong></p>
          </div>
        </section>
      )}

      {/* Footer Section */}
      <footer className={styles.footer}>
        <p>© 2024 问旅 | 粤ICP备2024268786号-3</p>
      </footer>
    </div>
  );
};

export default Home;
